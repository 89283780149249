<template>
  <v-container fluid class="dashboard px-0 grey lighten-1">
    <div class="text-center">
      <div
        v-if="isAuthenticated && user"
        class="pa-3 d-flex align-center flex-column"
      >
        <div class="user-card" @click="showDetails">
          <div class="px-3 pb-1 d-flex flex-column">
            <div class="card-user">
              {{ user.firstName }} {{ user.lastName }}
            </div>
            <div class="card-number">
              {{ $t("dashboard.cardNumber") }}
              <span>{{ user.fidelityCard }}</span>
            </div>
          </div>
        </div>
        <div>{{ $t("dashboard.showBarcode") }}</div>
      </div>
      <div v-else class="px-3 pb-3">
        <img
          src="/img_layout/GALA_club_fronte_not-logged.svg"
          class="card-img my-3"
        />
        <div class="mb-3 text-body-0">{{ $t("dashboard.subtitle") }}</div>
        <v-btn
          :block="$vuetify.breakpoint.xsOnly"
          depressed
          rounded
          color="primary"
          large
          class="px-sm-12"
          @click="doLoginDashboard"
          >{{ $t("dashboard.login") }}</v-btn
        >
      </div>
    </div>
    <div>
      <v-list
        v-for="(section, index) in menuSections"
        :key="'section-' + index"
        dense
        flat
        class="custom-list"
      >
        <v-subheader>{{ $t(section.title) }}</v-subheader>
        <v-list-item-group color="primary">
          <v-list-item
            v-for="(item, i) in section.items"
            :key="'item-' + i"
            @click="handleLogin(item.routePath)"
          >
            <v-list-item-icon>
              <v-icon v-text="'$' + item.icon" color="primary"></v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $t(item.title) }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
      <v-list dense flat class="custom-list py-0" v-if="isAuthenticated">
        <v-list-item-group color="error">
          <v-list-item @click="doLogout">
            <v-list-item-icon>
              <v-icon v-text="'$logout'" color="error"></v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="error--text">{{
                $t("dashboard.logout")
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </div>
    <i18n path="footer.version" tag="div" class="my-2 text-center">
      {{ version }}
    </i18n>
  </v-container>
</template>
<style lang="scss">
.dashboard {
  min-height: calc(100vh - 142px);
  .card-img {
    width: 100%;
    max-width: 380px;
    height: 210px;
  }
  .user-card {
    width: 100%;
    max-width: 380px;
    height: 210px;
    background-color: $secondary;
    border-radius: 8px;
    margin: 2px;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    text-align: start;
    background: url("/img_layout/GALA_club_fronte.svg") center/cover no-repeat;
    .club-img {
      max-width: 70%;
      padding: 15px;
      height: auto;
    }
    .card-user {
      color: $white;
      font-size: 16px;
      font-weight: bold;
    }
    .card-number {
      color: $white;
      font-size: 16px;
      span {
        font-weight: bold;
      }
    }
  }
}
</style>
<script>
import login from "~/mixins/login";
import { mapActions, mapGetters } from "vuex";
import CardDetails from "@/components/profile/CardDetails.vue";

export default {
  name: "Dashboard",
  mixins: [login],
  data() {
    return {
      //user: null,
      menuSections: [
        {
          title: "dashboard.myActivities",
          items: [
            {
              name: "myCoupons",
              title: "dashboard.myCoupons",
              icon: "coupon",
              routePath: "/coupon/active"
            },
            {
              name: "usePoints",
              title: "dashboard.usePoints",
              icon: "star",
              routePath: "/coupon/discounts"
            },
            {
              name: "prizeHistory",
              title: "dashboard.prizeHistory",
              icon: "premi",
              routePath: "/coupon/history"
            },
            {
              name: "shoppingList",
              title: "dashboard.shoppingList",
              icon: "listaSpesa",
              routePath: "/gala-world/shoppingList"
            },
            {
              name: "myFavorites",
              title: "dashboard.myFavorites",
              icon: "preferiti",
              routePath: "/dashboard/myFavorites"
            },
            // {
            //   name: "getMorePoints",
            //   title: "dashboard.getMorePoints",
            //   icon: "ottieniPunti",
            //   routePath: "/dashboard/getMorePoints"
            // },
            //{
            //  name: "donatePoints",
            //  title: "dashboard.donatePoints",
            //  icon: "regalaPunti",
            //  routePath: "/dashboard/donatePoints"
            //},
            {
              name: "receipts",
              title: "dashboard.receipts",
              icon: "invoice",
              routePath: "/dashboard/receipts"
            }
          ]
        },
        {
          title: "dashboard.myAccount",
          items: [
            {
              name: "myData",
              title: "dashboard.myData",
              icon: "profile",
              routePath: "/dashboard/profile"
            },
            {
              name: "chagePassword",
              title: "dashboard.chagePassword",
              icon: "key",
              routePath: "/dashboard/reset-password"
            },
            {
              name: "notificationsAndMessages",
              title: "dashboard.notificationsAndMessages",
              icon: "campanella",
              routePath: ""
            },
            {
              name: "privacy",
              title: "dashboard.privacy",
              icon: "privacy",
              routePath: "/dashboard/privacy"
            }
          ]
        }
      ]
    };
  },
  computed: {
    ...mapGetters({
      user: "cart/getUser"
    }),
    version() {
      return global.config.version;
    }
  },
  methods: {
    ...mapActions({
      logout: "cart/doLogout"
    }),
    async doLogout() {
      await this.logout();
      this.$router.push({
        name: "Home",
        path: "/"
      });
    },
    async doLoginDashboard() {
      await this.doLogin();
      // if (isLoggedIn) {
      //   this.fetchUserData();
      // }
    },
    async handleLogin(path) {
      if (this.isAuthenticated) {
        this.$router.push(path);
      } else {
        let isLoggedIn = await this.doLogin();
        if (isLoggedIn) {
          this.$router.push(path);
        }
      }
    },
    async showDetails() {
      let res = await this.$dialog.show(CardDetails, {
        waitForResult: true,
        fullscreen: true
      });
      if (res) {
        console.log(res);
      }
    }
  }
};
</script>
